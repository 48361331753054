import React, { Component } from "react";
import { connectAPI } from "../helpers/api";
import { validerFormInscription } from "../helpers/validationFormInscription";
import InputMask from "react-input-mask";
import { SessionData } from "../helpers/SessionData";
import { Link } from "react-router-dom";

class Inscription extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);

    this.state = {
      nom: "",
      prenom: "",
      email: "",
      mdp: "",
      confirmation: "",
      tel: "",
      matricule: "",
      conditions: false,
      erreurNom: "",
      erreurPrenom: "",
      erreurEmail: "",
      erreurMDP: "",
      erreurConfirmation: "",
      erreurTel: "",
      erreurMatricule: "",
      erreurConditions: "",
    };
  }

  erreursInitiales = () => {
    this.setState({
      erreurNom: "",
      erreurPrenom: "",
      erreurEmail: "",
      erreurMDP: "",
      erreurConfirmation: "",
      erreurTel: "",
      erreurMatricule: "",
      erreurConditions: "",
    });
  };

  handleNomChange = (event) => {
    this.setState({
      nom: event.target.value,
      erreurNom: "",
    });
  };

  handlePrenomChange = (event) => {
    this.setState({
      erreurPrenom: "",
      prenom: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      erreurEmail: "",
      email: event.target.value,
    });
  };

  handleMDPChange = (event) => {
    this.setState({
      erreurMDP: "",
      mdp: event.target.value,
    });
  };

  handleConfirmationChange = (event) => {
    this.setState({
      erreurConfirmation: "",
      confirmation: event.target.value,
    });
  };

  handleTelChange = (event) => {
    this.setState({
      erreurTel: "",
      tel: event.target.value,
    });
  };

  handleMatriculeChange = (event) => {
    this.setState({
      erreurMatricule: "",
      matricule: event.target.value,
    });
  };

  handleCheck = (event) => {
    this.setState({
      conditions: event.target.checked,
    });
  };

  inscription = async () => {
    let data = {
      idUser: 0,
      avatar: 1,
      matricule: this.state.matricule,
      firstName: this.lettreMajuscule(this.state.prenom),
      lastName: this.lettreMajuscule(this.state.nom),
      phoneNumber: this.formatTel(this.state.tel),
      email: this.state.email,
      password: this.state.mdp,
    };

    const result = await connectAPI("add_user.php", data);

    if (result.data == null)
      alert(
        "Ce courriel et/ou ce matricule est déjà créé. Pour plus d’informations, veuillez communiquer avec l’AGECTR."
      );
    else {
      alert("Le compte a bien été créé");

      let u = {
        nom: result.data.lastName,
        prenom: result.data.firstName,
        email: result.data.email,
        matricule: result.data.matricule,
        idUser: result.data.idUser,
        tel: result.data.phoneNumber,
        st: result.data.sessionToken,
      };
      SessionData().setUserInfos(u);

      window.open("https://livres-usages.agectr.com", "_self");
      //navigate('/compte/infos')
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let validation = validerFormInscription(this.state);

    this.erreursInitiales();

    if (!validation.isValid) {
      this.setState({
        erreurNom: validation.nom,
        erreurPrenom: validation.prenom,
        erreurEmail: validation.email,
        erreurMDP: validation.mdp,
        erreurConfirmation: validation.confirmation,
        erreurTel: validation.tel,
        erreurMatricule: validation.matricule,
        erreurConditions: validation.conditions,
      });
    } else {
      this.inscription();
    }
  };

  lettreMajuscule = (texte) => {
    if (texte.includes(" ")) {
      let retour = "";
      let tab = texte.split(" ");

      for (let i = 0; i < tab.length; i++) {
        retour += tab[i].charAt(0).toUpperCase() + tab[i].slice(1) + " ";
      }
      return retour.trim();
    } else if (texte.includes("-")) {
      let retour = "";
      let tab = texte.split("-");

      for (let i = 0; i < tab.length; i++) {
        retour += tab[i].charAt(0).toUpperCase() + tab[i].slice(1) + "-";
      }
      return retour.slice(0, -1);
    } else return texte.charAt(0).toUpperCase() + texte.slice(1);
  };

  formatTel = (valeur) => {
    valeur = valeur.replace(" ", "");
    valeur = valeur.replace("-", "");
    return valeur;
  };

  render() {
    return (
      <div>
        <div className="container-fluid mb-5">
          <div className="row pt-sm-5">
            <div className="col-12 col-sm-8 col-md-10 p-4 mx-auto primaire rounded-3 shadow text-white">
              <h1 className="text-center text-white">Création de compte</h1>
              <hr className="col-8 border border-primary border-3 mx-auto" />

              <form
                className="mt-5 needs-validation"
                noValidate
                onSubmit={this.handleSubmit}
              >
                <p className="mb-5">Les champs avec * sont obligatoires</p>
                <h3 className="mb-2">Informations personnelles</h3>
                <div className="row mb-2">
                  <div className="col-12 col-md-6">
                    <label htmlFor="idPrenom" className="form-label fs-5">
                      Prénom *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ex: Michel"
                      onChange={this.handlePrenomChange}
                      required
                      aria-label="Prénom"
                      aria-describedby="idPrenom"
                    />
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurPrenom}
                    </div>
                  </div>
                  <div className="col-12  col-md-6 mb-3">
                    <label htmlFor="idNom" className="form-label fs-5">
                      Nom *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ex: Massé"
                      onChange={this.handleNomChange}
                      required
                      aria-label="Nom"
                      aria-describedby="idNom"
                    />
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurNom}
                    </div>
                  </div>
                </div>
                <div className="row  mb-5">
                  <div className="col-12  col-md-6 mb-3">
                    <label htmlFor="idTel" className="form-label fs-5">
                      Téléphone *
                    </label>
                    {/* <input type="text" className="form-control" placeholder="ex: 8199638524" maxLength={'10'} onChange={this.handleTelChange}  aria-label="Téléphone" aria-describedby="idTel" /> */}
                    <InputMask
                      mask="999 999-9999"
                      maskChar=" "
                      className="form-control"
                      onChange={this.handleTelChange}
                      placeholder="ex: 819 963-8524"
                    />
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurTel}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <label htmlFor="idMatricule" className="form-label fs-5">
                      Matricule (7 caractères)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Matricule"
                      maxLength={"7"}
                      onChange={this.handleMatriculeChange}
                      required
                      aria-label="Matricule"
                      aria-describedby="idMatricule"
                    />
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurMatricule}
                    </div>
                  </div>
                </div>

                <h3>Informations de connexion</h3>
                <div className="row mb-4">
                  <div className="col-12  col-md-6 mb-3">
                    <label htmlFor="idEmail" className="form-label fs-5">
                      Courriel *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="ex: michel.masse@mail.com"
                      onChange={this.handleEmailChange}
                      required
                      aria-label="Email"
                      aria-describedby="idEmail"
                    />
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurEmail}
                    </div>
                  </div>

                  <div className="col-12  col-md-6 mb-3">
                    <div className="col-12 mb-3">
                      <label htmlFor="idMDP" className="form-label fs-5 ">
                        Mot de passe *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={this.handleMDPChange}
                        required
                        aria-label="Mot de passe"
                        aria-describedby="idMDP"
                      />
                      <div className="text-start form-text col-12 text-warning">
                        {this.state.erreurMDP}
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <label
                        htmlFor="idConfirmation"
                        className="form-label fs-5"
                      >
                        Confirmez le mot de passe *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={this.handleConfirmationChange}
                        required
                        aria-label="Confirmer"
                        aria-describedby="idConfirmation"
                      />
                      <div className="text-start form-text col-12 text-warning">
                        {this.state.erreurConfirmation}
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mb-2">Conditions d'utilisation</h3>
                <div className="row mb-4 mt-3">
                  <div className="col-1 text-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onClick={this.handleCheck}
                      required
                      id="idConditions"
                    />
                  </div>
                  <div className="col-11 ">
                    <label htmlFor="idConditions" className="form-label fs-5">
                      J'ai lu et j'accecpte{" "}
                      <a
                        href="idModalConditions"
                        data-bs-toggle="modal"
                        data-bs-target="#idModalConditions"
                      >
                        les conditions d'utilisation
                      </a>
                    </label>
                    <div className="text-start form-text col-12 text-warning">
                      {this.state.erreurConditions}
                    </div>
                  </div>
                </div>
                <div className=" mb-3 text-center">
                  <button
                    type="submit"
                    id="envoyer"
                    className="btn btn-primary text-white btn-lg col-12 col-md-6"
                  >
                    Créer le compte
                  </button>
                </div>
              </form>
              <div className="row mb-4 mt-3">
                <div className="col-12 text-center">
                  <Link
                    to="/compte/connexion"
                    className="fs-4  text-white text-decoration-none"
                  >
                    Connexion
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="idModalConditions"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="idModalConditionsLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="idModalConditionsLabel">
                  Conditions d'utilisation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Par le présent contrat, je m’engage à respecter les règlements
                  de la consigne de livres usagés. <br />
                  Ainsi:
                </p>

                <ul>
                  <li>
                    Je consens à respecter la date limite pour la remise de
                    l’argent et le renouvèlement de mes livres et à ne pas
                    revenir contre l’AGECTR inc. si je ne respecte pas la date
                    (contrat d’un an);
                  </li>
                  <li>
                    Je consens que l’AGECTR inc. conserve mes livres et/ou
                    l’argent provenant de la vente de ces derniers si je ne
                    respecte pas la date limite pour récupérer mes biens;
                  </li>
                  <li>
                    Je prends note que si les livres ne sont pas vendus, je peux
                    toujours renouveler le contrat directement au bureau de
                    l’AGECTR ou par l’application mobile avant la date limite;
                  </li>
                  <li>
                    Je consens à l'utilisation de témoins selon les termes de la
                    politique de confidentialité.
                  </li>
                  <li className="fw-bold">
                    L’AGECTR inc. se dégage de toute responsabilité en ce qui a
                    trait au vol ou aux pertes de volumes. Elle fera cependant
                    tout ce qui est en son pouvoir afin d’éviter de telles
                    situations.
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inscription;
